<template>
    <div>
        <div class="golia-simulated-table">
            <div class="g-t-head">
                <div
                    class="g-tr"
                    :style="
                        anyFluidFields
                            ? `grid-template-columns: ${fields.column_template}`
                            : '--fields-count:' + (fields.list.length + 1)
                    "
                >
                    <div
                        class="g-th"
                        :class="sorted_field.key === field.key ? 'sorting' : ''"
                        v-for="field in fields.list"
                        :key="field.key"
                        @click="sortColumn(field)"
                    >
                        <span>{{ field.label }}</span>
                        <i
                            class="fa-solid fa-sort-up"
                            v-show="
                                sorted_field.key === field.key &&
                                sorted_field.sort_direction == 'asc'
                            "
                        ></i>
                        <i
                            class="fa-solid fa-sort-down"
                            v-show="
                                sorted_field.key === field.key &&
                                sorted_field.sort_direction == 'desc'
                            "
                        ></i>
                        <i
                            class="fa-solid fa-sort"
                            v-show="
                                (sorted_field.key == null || sorted_field.key !== field.key) &&
                                field.sortable
                            "
                        ></i>
                    </div>

                    <!-- Empty TH for the action buttons -->
                    <div class="g-th h-100"></div>
                </div>
            </div>
            <div class="g-t-body">
                <recycle-scroller
                    :buffer="100"
                    :items="sortedTrucks"
                    :item-size="35"
                    direction="vertical"
                    :key-field="'plate'"
                    class="truck-table h-100 custom-scrollbar"
                >
                    <template v-slot="{ item }">
                        <div
                            class="g-tr"
                            :class="item.on_focus ? 'on-focus' : ''"
                            :style="
                                anyFluidFields
                                    ? `grid-template-columns: ${fields.column_template}`
                                    : '--fields-count:' + (fields.list.length + 1)
                            "
                            @click="focusTruck(item)"
                        >
                            <div class="g-td" v-for="field in fields.list" :key="field.key">
                                <component
                                    :is="field.component"
                                    :truck="item"
                                    :fields="fields.list"
                                    :key="item.plate"
                                ></component>
                            </div>
                            <div class="g-td">
                                <div
                                    class="actions-buttons d-flex"
                                    :class="
                                        anyFluidFields
                                            ? 'justify-content-center'
                                            : 'justify-content-end me-2'
                                    "
                                >
                                    <golia-button
                                        variant="btn-fill dark-blue"
                                        icon="fa-route"
                                        size="sm"
                                        @click.stop="openTruckPlanner(item, $event)"
                                    />
                                    <golia-button
                                        variant="btn-fill dark-blue"
                                        icon="fa-chart-mixed"
                                        size="sm"
                                        @click.stop="openTruckActivities(item, $event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </recycle-scroller>
            </div>
        </div>
    </div>
</template>

<script>
import { h, toRefs } from 'vue'
import { mapState } from 'vuex'
import { useVehicleStatus } from '@/composables/useVehicleStatus'
import { useVehicleAlerts } from '@/composables/useVehicleAlerts'
import { RecycleScroller } from 'vue-virtual-scroller'

export default {
    name: 'TableViewTruckList',
    props: {
        trucks: {
            type: Array
        },
        drivers: {
            type: [Object, Array]
        },
        collapsed: {
            type: Boolean,
            required: true
        }
    },
    components: {
        RecycleScroller
    },
    data() {
        return {
            sorted_field: {
                key: null,
                sort_direction: null
            },
            table_fields: {
                status: {
                    key: 'activity_type',
                    label: this.__('status'),
                    width: '60px',
                    component: {
                        name: 'StatusField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        setup(props) {
                            const { truck } = toRefs(props)
                            const { vehicle_status, vehicle_color } = useVehicleStatus(truck)

                            return {
                                vehicle_status,
                                vehicle_color
                            }
                        },
                        render() {
                            return h('span', {
                                class: 'table-list-status-color',
                                style: `--status-color: var(${this.vehicle_color})`
                            })
                        }
                    }
                },
                plate: {
                    key: 'plate',
                    label: this.__('plate'),
                    width: '100px',
                    sortable: true,
                    component: {
                        name: 'PlateField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('b', {}, this.truck.plate)
                        }
                    }
                },
                custom_id: {
                    key: 'details.custom_id',
                    label: this.__('custom_id'),
                    width: '100px',
                    sortable: true,
                    component: {
                        name: 'CustomIDField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('b', {}, this.truck.details.custom_id)
                        }
                    }
                },
                activity: {
                    key: 'lastpoint.activity',
                    label: this.__('activity'),
                    width: '60px',
                    component: {
                        name: 'ActivityField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            switch (this.truck.lastpoint.activity) {
                                case 0:
                                    return h('span', {}, [
                                        h('i', {
                                            style: 'color: var(--rest-color)',
                                            class: 'fa-solid fa-bed'
                                        }),
                                        h('span', { class: 'ms-2' }, this.__('rest'))
                                    ])
                                case 1:
                                    return h('span', {}, [
                                        h('i', {
                                            style: 'color: var(--availability-color)',
                                            class: 'fak fa-availability'
                                        }),
                                        h('span', { class: 'ms-2' }, this.__('availability'))
                                    ])
                                case 2:
                                    return h('span', {}, [
                                        h('i', {
                                            style: 'color: var(--work-color)',
                                            class: 'fak fa-work'
                                        }),
                                        h('span', { class: 'ms-2' }, this.__('work'))
                                    ])
                                case 3:
                                    return h('span', {}, [
                                        h('i', {
                                            style: 'color: var(--driving-color)',
                                            class: 'fa-solid fa-steering-wheel'
                                        }),
                                        h('span', { class: 'ms-2' }, this.__('driving'))
                                    ])
                                default:
                                    return h('span', {}, [h('span', {}, this.__('none'))])
                            }
                        }
                    }
                },
                codriver: {
                    key: 'lastpoint.codriver',
                    label: this.__('codriver'),
                    width: '60px',
                    component: {
                        name: 'CodriverField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h(
                                'span',
                                {},
                                this.truck.lastpoint.codriver ? this.__('yes') : this.__('no')
                            )
                        }
                    }
                },
                speed: {
                    key: 'lastpoint.speed',
                    label: this.__('speed'),
                    width: '80px',
                    sortable: true,
                    component: {
                        name: 'SpeedField',
                        props: {
                            truck: {
                                type: Object
                            },
                            fields: {
                                type: Array
                            }
                        },
                        render() {
                            return h('span', {}, this.speed + ' km/h')
                        },
                        computed: {
                            speed() {
                                if (this.fields.includes('lastpoint.tacho_speed')) {
                                    return this.truck.lastpoint.speed
                                } else {
                                    return (
                                        this.truck.lastpoint.tacho_speed ||
                                        this.truck.lastpoint.speed
                                    )
                                }
                            }
                        }
                    }
                },
                tacho_speed: {
                    key: 'lastpoint.tacho_speed',
                    label: this.__('tacho_speed'),
                    width: '80px',
                    sortable: true,
                    component: {
                        name: 'TachoSpeedField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('span', {}, (this.truck.lastpoint.tacho_speed || 0) + ' km/h')
                        }
                    }
                },
                heading: {
                    key: 'lastpoint.heading',
                    label: this.__('heading'),
                    width: '80px',
                    component: {
                        name: 'HeadingField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('div', {}, [
                                h('i', {
                                    class: 'fa-solid fa-arrow-up',
                                    style: {
                                        transform: this.heading
                                    }
                                }),
                                h(
                                    'span',
                                    { class: 'ms-2', style: { fontSize: '14px' } },
                                    this.truck.lastpoint.heading + '°'
                                )
                            ])
                        },
                        computed: {
                            heading() {
                                return `rotate(${this.truck.lastpoint.heading}deg)`
                            }
                        }
                    }
                },
                altitude: {
                    key: 'lastpoint.altitude',
                    label: this.__('altitude'),
                    width: '80px',
                    sortable: true,
                    component: {
                        name: 'AltitudeField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('span', {}, parseInt(this.truck.lastpoint.altitude) + ' mslm')
                        }
                    }
                },
                battery_level: {
                    key: 'lastpoint.telemetry.battery_level',
                    label: this.__('battery_level'),
                    width: '80px',
                    sortable: true,
                    component: {
                        name: 'BatteryLevelField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h(
                                'b',
                                {},
                                this.truck.lastpoint?.telemetry?.battery_level
                                    ? this.truck.lastpoint?.telemetry?.battery_level + ' %'
                                    : ''
                            )
                        }
                    }
                },
                fuel_level: {
                    key: 'lastpoint.fuel_level',
                    label: this.__('fuel_level'),
                    width: '80px',
                    sortable: true,
                    component: {
                        name: 'FuelLevelField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h(
                                'b',
                                {},
                                this.truck.lastpoint.fuel_level
                                    ? this.truck.lastpoint.fuel_level + ' %'
                                    : ''
                            )
                        }
                    }
                },
                rpm_engine: {
                    key: 'lastpoint.rpm_engine',
                    label: this.__('rpm_engine'),
                    width: '100px',
                    sortable: true,
                    component: {
                        name: 'RPMEngineField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h(
                                'b',
                                {},
                                this.truck.lastpoint.engine_rpm >= 0
                                    ? this.truck.lastpoint.engine_rpm + ' rpm'
                                    : '-'
                            )
                        }
                    }
                },
                ignition: {
                    key: 'lastpoint.ignition',
                    label: this.__('ignition'),
                    width: '80px',
                    component: {
                        name: 'IgnitionField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h(
                                'span',
                                {},
                                this.truck.lastpoint.ignition ? this.__('yes') : this.__('no')
                            )
                        }
                    }
                },
                odometer: {
                    key: 'lastpoint.odometer',
                    label: this.__('odometer'),
                    width: '120px',
                    sortable: true,
                    component: {
                        name: 'OdometerField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('b', {}, this.formattedOdometer)
                        },
                        computed: {
                            formattedOdometer() {
                                // Format example 1234567 -> 1.234.567
                                return this.truck.lastpoint.odometer
                                    ? this.truck.lastpoint.odometer
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' km'
                                    : ''
                            }
                        }
                    }
                },
                gps_odometer: {
                    key: 'lastpoint.gps_odometer',
                    label: this.__('odometer_gps'),
                    width: '120px',
                    sortable: true,
                    component: {
                        name: 'GPSOdometerField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('b', {}, this.formattedGPSOdometer)
                        },
                        computed: {
                            formattedGPSOdometer() {
                                return this.truck.lastpoint.gps_odometer
                                    ? this.truck.lastpoint.gps_odometer
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' km'
                                    : ''
                            }
                        }
                    }
                },
                daily_distance: {
                    key: 'lastpoint.daily_distance',
                    label: this.__('daily_distance'),
                    width: '120px',
                    sortable: true,
                    component: {
                        name: 'DailyDistanceField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        data() {
                            return {
                                averageData: null
                            }
                        },
                        render() {
                            return h(
                                'b',
                                {},
                                this.daily_distance ? this.daily_distance + ' km' : null
                            )
                        },
                        created() {
                            this.getAvarageData()
                        },
                        methods: {
                            async getAvarageData() {
                                try {
                                    const response = await this.$fetch(
                                        this.$env.GOLIA_API +
                                            '/geofleet/trucks/' +
                                            this.truck.id +
                                            '/average'
                                    )

                                    if (!response.ok) throw Error(response.statusText)

                                    let avarageDataResponse = await response.json()

                                    this.averageData = avarageDataResponse.data
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                        },
                        watch: {
                            truck: {
                                handler: function () {
                                    this.getAvarageData()
                                },
                                deep: true
                            }
                        },
                        computed: {
                            daily_distance() {
                                if (this.averageData && this.averageData?.distance) {
                                    return (
                                        this.averageData?.distance?.toFixed(0) ||
                                        this.averageData?.gps_distance?.toFixed(0)
                                    )
                                } else {
                                    return null
                                }
                            }
                        }
                    }
                },
                gnss_status: {
                    key: 'lastpoint.telemetry.gnss_status',
                    label: this.__('gnss_status'),
                    width: '80px',
                    component: {
                        name: 'GnssStatusField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('span', {}, this.gnss_status)
                        },
                        computed: {
                            gnss_status() {
                                let value = this.truck.lastpoint?.telemetry?.gnss_status
                                return value ? this.__('gnss_status_values.' + value) : ''
                            }
                        }
                    }
                },
                gsm_signal: {
                    key: 'lastpoint.telemetry.gsm_signal',
                    label: this.__('gsm_signal'),
                    width: '80px',
                    component: {
                        name: 'GSMSignalField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('div', {}, [
                                h('i', {
                                    class: `fa-solid fa-${this.gsm_signal.icon} ${
                                        this.gsm_signal.value == 0
                                            ? 'text-gray-darker'
                                            : 'text-success'
                                    }`
                                }),
                                h(
                                    'b',
                                    { class: 'ms-2', style: { fontSize: '14px' } },
                                    this.gsm_signal.value
                                )
                            ])
                        },
                        computed: {
                            gsm_signal() {
                                let value = this.truck.lastpoint?.telemetry?.gsm_signal
                                let values = {
                                    0: 'signal-slash',
                                    1: 'signal-weak',
                                    2: 'signal-fair',
                                    3: 'signal-good',
                                    4: 'signal-strong',
                                    5: 'signal'
                                }

                                return {
                                    value: value,
                                    icon: values[value]
                                }
                            }
                        }
                    }
                },
                address: {
                    key: 'lastpoint.address',
                    label: this.__('address'),
                    width: '250px',
                    fluidWidth: true,
                    component: {
                        name: 'AddressField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        render() {
                            return h('reverse-geocoding', {
                                props: {
                                    latLon: this.latLon,
                                    custom_fields: [
                                        'country',
                                        'state',
                                        'province',
                                        'county',
                                        'village',
                                        'road'
                                    ]
                                },
                                style: {
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }
                            })
                        },
                        computed: {
                            latLon() {
                                return [this.truck.lastpoint.lat, this.truck.lastpoint.lng]
                            }
                        }
                    }
                },
                alerts: {
                    key: 'alerts',
                    label: this.__('alerts'),
                    width: '120px',
                    component: {
                        name: 'AlertsField',
                        props: {
                            truck: {
                                type: Object
                            }
                        },
                        setup(props) {
                            const { truck } = toRefs(props)
                            const { alerts } = useVehicleAlerts(truck)

                            return {
                                alerts
                            }
                        },
                        render() {
                            let alertsIcons = []

                            Object.keys(this.alerts).forEach((alert) => {
                                if (this.alerts[alert]) {
                                    alertsIcons.push(
                                        h('i', {
                                            class: this.alerts[alert].icon + ' text-danger me-2',
                                            attrs: {
                                                title: this.__(this.alerts[alert].problem)
                                            }
                                        })
                                    )
                                }
                            })

                            return h('div', {}, alertsIcons)
                        }
                    }
                }
            }
        }
    },
    methods: {
        focusTruck: function (truck) {
            this.$emit('focus', truck)
        },
        openTruckPlanner: function (truck) {
            this.$emit('plan', truck)
        },
        openTruckActivities: function (truck) {
            this.$emit('activity', truck)
        },
        sortColumn: function (field) {
            if (!field.sortable) return

            // Firs click: asc, second click: desc, third click: no sort
            if (this.sorted_field.key == field.key) {
                if (this.sorted_field.sort_direction == 'asc') {
                    this.sorted_field.sort_direction = 'desc'
                } else {
                    this.sorted_field.sort_direction = null
                    this.sorted_field.key = null
                }
            } else {
                this.sorted_field.key = field.key
                this.sorted_field.sort_direction = 'asc'
            }
        }
    },
    computed: {
        sortedTrucks() {
            if (!this.sorted_field.key) return this.trucks

            let clonedTrucks = this.trucks.map((truck) => Object.assign({}, truck))

            let sorted_trucks = clonedTrucks.sort((a, b) => {
                let a_value = this.resolvePath(this.sorted_field.key, a)
                let b_value = this.resolvePath(this.sorted_field.key, b)

                if (a_value < b_value) {
                    return this.sorted_field.sort_direction == 'asc' ? -1 : 1
                }
                if (a_value > b_value) {
                    return this.sorted_field.sort_direction == 'asc' ? 1 : -1
                }
                return 0
            })

            return sorted_trucks
        },
        fields() {
            var field_list = []
            var fields_column_template = ''

            if (!this.config.vehicleListTableFields) return []
            if (!this.config.vehicleListTableFields.fields) return []

            this.config.vehicleListTableFields.fields.forEach((field) => {
                var this_field = this.table_fields[field.value] || null
                var field_width = !this_field.fluidWidth ? this_field.width : '1fr'

                if (this.config?.vehicleListFluidTable && this_field.fluidWidth) {
                    field_width = this_field.width
                }

                if (this_field) {
                    field_list.push(this_field)
                    fields_column_template += `${field_width || '1fr'} `
                }
            })

            fields_column_template += '80px'

            return {
                list: field_list,
                column_template: fields_column_template
            }
        },
        anyFluidFields() {
            return this.fields.list.some((field) => field.fluidWidth)
        },
        ...mapState({
            config: (state) => state.geoFleet.config
        })
    }
}
</script>

<style scoped lang="scss">
.golia-simulated-table {
    --fields-count: 0;
    --column-one: 100px;
    --column-two: 80px;
    --internal-gap: 5px;

    padding: 0px 10px;
    .g-t-head {
        margin-bottom: 10px;

        > .g-tr {
            align-items: center;
        }
        .g-tr .g-th {
            background: var(--main-inner-bg-color);
            padding: var(--internal-gap);
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover,
            &.sorting {
                background: var(--main-gray--darker);
                cursor: pointer;
            }
        }
    }
    .g-t-body {
        // overflow-y: scroll;
        height: 100%;
        width: calc(100% - 15px);
        position: absolute;
    }
    .g-tr {
        $columns: var(--fields-count);

        display: grid;
        grid-template-columns: repeat(var(--fields-count), 1fr);
        align-items: center;

        .g-td {
            padding: var(--internal-gap);
            border-bottom: 1px solid var(--main-gray);
            border-right: 1px solid var(--main-gray);

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            min-height: 35px;

            span,
            b {
                font-size: 13px;

                &:hover {
                    color: var(--dark-blue);
                }
            }

            .actions-buttons {
                gap: 5px;
                .btn {
                    font-size: 12px;
                    border-radius: 5px;
                    padding: 2px 5px;
                }
            }
        }
    }

    .g-t-body .g-tr:hover,
    .g-t-body .g-tr.on-focus {
        background: var(--main-focus);
        cursor: pointer;

        .g-td {
            border-color: var(--main-focus);
        }
    }
}
.table-list-status-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    background-color: var(--status-color);
}
</style>
