var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"golia-simulated-table"},[_c('div',{staticClass:"g-t-head"},[_c('div',{staticClass:"g-tr",style:(_vm.anyFluidFields
                        ? `grid-template-columns: ${_vm.fields.column_template}`
                        : '--fields-count:' + (_vm.fields.list.length + 1))},[_vm._l((_vm.fields.list),function(field){return _c('div',{key:field.key,staticClass:"g-th",class:_vm.sorted_field.key === field.key ? 'sorting' : '',on:{"click":function($event){return _vm.sortColumn(field)}}},[_c('span',[_vm._v(_vm._s(field.label))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.sorted_field.key === field.key &&
                            _vm.sorted_field.sort_direction == 'asc'
                        ),expression:"\n                            sorted_field.key === field.key &&\n                            sorted_field.sort_direction == 'asc'\n                        "}],staticClass:"fa-solid fa-sort-up"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.sorted_field.key === field.key &&
                            _vm.sorted_field.sort_direction == 'desc'
                        ),expression:"\n                            sorted_field.key === field.key &&\n                            sorted_field.sort_direction == 'desc'\n                        "}],staticClass:"fa-solid fa-sort-down"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                            (_vm.sorted_field.key == null || _vm.sorted_field.key !== field.key) &&
                            field.sortable
                        ),expression:"\n                            (sorted_field.key == null || sorted_field.key !== field.key) &&\n                            field.sortable\n                        "}],staticClass:"fa-solid fa-sort"})])}),_c('div',{staticClass:"g-th h-100"})],2)]),_c('div',{staticClass:"g-t-body"},[_c('recycle-scroller',{staticClass:"truck-table h-100 custom-scrollbar",attrs:{"buffer":100,"items":_vm.sortedTrucks,"item-size":35,"direction":"vertical","key-field":'plate'},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"g-tr",class:item.on_focus ? 'on-focus' : '',style:(_vm.anyFluidFields
                                ? `grid-template-columns: ${_vm.fields.column_template}`
                                : '--fields-count:' + (_vm.fields.list.length + 1)),on:{"click":function($event){return _vm.focusTruck(item)}}},[_vm._l((_vm.fields.list),function(field){return _c('div',{key:field.key,staticClass:"g-td"},[_c(field.component,{key:item.plate,tag:"component",attrs:{"truck":item,"fields":_vm.fields.list}})],1)}),_c('div',{staticClass:"g-td"},[_c('div',{staticClass:"actions-buttons d-flex",class:_vm.anyFluidFields
                                        ? 'justify-content-center'
                                        : 'justify-content-end me-2'},[_c('golia-button',{attrs:{"variant":"btn-fill dark-blue","icon":"fa-route","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.openTruckPlanner(item, $event)}}}),_c('golia-button',{attrs:{"variant":"btn-fill dark-blue","icon":"fa-chart-mixed","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.openTruckActivities(item, $event)}}})],1)])],2)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }